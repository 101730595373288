'use client'

import {useEffect, useState} from 'react'
import {HgButtonLink, HgTextLink} from '~/design-system/hg/components'
import {externalLinks, mercuryAppPaths} from '~/routing/external-links'
import {hasLoggedInCookie} from '~/utils/hasLoggedInCookie'
import {type CTAButtonsProps} from '../types'
import {mercuryAppUrlWithPath} from '~/data/mercury'

const CTAButtons = ({
  primaryButtonProps,
  isCompressed,
  tabIndex = 0,
}: CTAButtonsProps) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    setIsLoggedIn(hasLoggedInCookie())
  }, [])

  return isLoggedIn ? (
    <>
      <HgTextLink
        className="hidden arcadia-ui-1 md:flex"
        href={mercuryAppUrlWithPath(mercuryAppPaths.upnRedirectOrDashboard)}
        tabIndex={tabIndex}
        data-analytics-event-name="dashboard"
        variant="revealBackground"
      >
        Dashboard
      </HgTextLink>
      <HgButtonLink
        variant="tonal"
        href={mercuryAppUrlWithPath(mercuryAppPaths.upnRedirectOrDashboard)}
        className="w-full sm:w-auto md:hidden"
        tabIndex={tabIndex}
        data-analytics-event-name="dashboard"
      >
        Dashboard
      </HgButtonLink>
    </>
  ) : (
    <div className="flex w-full flex-row-reverse items-center justify-end gap-s3 sm:w-auto sm:justify-center md:flex-row">
      {!isCompressed && (
        <>
          <HgTextLink
            data-analytics-event-name="login"
            className="hidden arcadia-ui-1 md:flex"
            href={externalLinks.auth.login}
            tabIndex={tabIndex}
            variant="revealBackground"
          >
            Log In
          </HgTextLink>
          <HgButtonLink
            data-analytics-event-name="login"
            variant="tonal"
            href={externalLinks.auth.login}
            className="flex w-full sm:w-auto md:hidden"
            tabIndex={tabIndex}
          >
            Log In
          </HgButtonLink>
        </>
      )}
      <HgButtonLink
        variant="primary"
        className="flex w-full sm:w-auto md:flex"
        tabIndex={tabIndex}
        {...primaryButtonProps}
      />
    </div>
  )
}

export default CTAButtons
